// theme colors
$primary: #026CDF;
$secondary: #0150A7;
$error: #d32752;
$info: #f6c31c;
$Success: #1BAB1E;

$AzureDarkestColor: #013670;
$AzureReverseColor: #3396FF;
$AzureReverseLightColor: #80B5EF;
$AzureReverseLightestColor: #BFDAF6;
$AzureLightColor: #D6E7FA;
$AzureLightestColor: #EBF4FD;

// Black & Grey Colors
$OnyxColor: #262626;
$GraysColor: #646464;
$StateColor: #999999;
$MoonrockColor: #BFBFBF;
$DiatomiteColor: #EBEBEB;
$QuartzColor: #F6F6F6;
$WhiteColor: #FFFFFF;
$BlackPearlColor: #1F262D;
$BlackPearlLightColor: #8F9296;
$BlackPearlLightestColor: #E3E4E5;

// Alert Colors

$AlertDarkColor: #C69A22;
$AlertColor: #F2BD2A;
$AlertLightColor: #FAE5AA;
$AlertLightestColor: #FDF5DF;
$ErrorDarkColor: #A22B2B;
$ErrorColor: #D93A3A;
$ErrorLightColor: #F3C3C3;
$ErrorLightestColor: #FBEBEB;


// color palette
$colors: (
        "primary": $primary,
        "secondary": $secondary,
        "error": $error,
        "info": $info,
        "blue": #1919e6,
        "red": #e61919,
        "yellow": #e6e619,
        "green": #19e635,
        "orange": #ffa600,
        "purple": #9900ff,
        "gray": #808080,
        "black": black,
        "white": white,
);

// spacing
$base-padding: 0.75rem; //16*0.75=12
$base-margin: 0.75rem;

// borders
$base-border-thickness: 1px;
$base-border-radius: 20px;

// box-shadow
$base-box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);

//font
$primary-font: 'Averta';

// font-weight
$thin: 100;
$light: 300;
$regular: 400;
$bold: 700;
$black: 900;

// Font size
$base-font-size: 16px;
$font-xs: $base-font-size - 4;
$font-sm: $base-font-size - 2;
$primary-fs: $base-font-size;
$font-md: $base-font-size + 2;
$font-lg: $base-font-size + 4;

