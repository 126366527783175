@import "src/assets/css/core/_variables";

.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-fill {
    /*   position: relative !important;
    top: 20px !important;
    padding: 30px 0px !important; */
    width: 100%;
    &.mat-form-field-invalid{
        .mat-form-field-flex{
            border-color: $error;
        }
    }

    .mat-form-field-wrapper {
        padding:0 0 5px 0;
        .mat-form-field-flex {
            background: $WhiteColor;
            border-radius: 10px;

            .mat-form-field-infix {
                mat-select {
                    .mat-select-trigger {
                        min-height: 17px;
                        .mat-select-arrow-wrapper {
                            /*  font-family: 'Material Icons';
                            content: 'expand_more'; */
                            .mat-select-arrow {
                                /* display:none !important; */
                                color: $primary;
                                position: relative;
                                border: 0px;
                                &::after{
                                    content: 'expand_more';
                                    font-family: 'Material Icons Outlined';
                                    display: block;
                                    font-size: 25px;
                                    position: absolute;
                                    right: 0px;
                                    top: -14px;
                                }
                            }
                        }
                    }
                }

                .mat-form-field-label-wrapper {
                    mat-label {
                        color: $primary !important;
                        text-transform: uppercase;
                        font-size: 15px;
                    }
                }
            }
        }
    }

    .mat-form-field-subscript-wrapper {
        /*display: none !important;*/
    }
    .mat-form-field-underline {
        display: none !important;
    }
   
}
