@use 'sass:math';

$utilities: (
        "padding": (
                "prefix": "p",
                "values": (
                        "0": 0,
                        "1": $base-padding,
                        "2": $base-padding * 2,
                        "3": $base-padding * 4,
                        "4": $base-padding * 6,
                        "5": $base-padding * 8
                ),
        ),
        "padding-left": (
                "prefix": "pl",
                "values": (
                        "0": 0,
                        "1": $base-padding,
                        "2": $base-padding * 2,
                        "3": $base-padding * 4,
                        "4": $base-padding * 6,
                        "5": $base-padding * 8
                ),
        ),
        "padding-right": (
                "prefix": "pr",
                "values": (
                        "0": 0,
                        "1": $base-padding,
                        "2": $base-padding * 2,
                        "3": $base-padding * 4,
                        "4": $base-padding * 6,
                        "5": $base-padding * 8
                ),
        ),
        "padding-top": (
                "prefix": "pt",
                "values": (
                        "0": 0,
                        "1": $base-padding,
                        "2": $base-padding * 2,
                        "3": $base-padding * 4,
                        "4": $base-padding * 6,
                        "5": $base-padding * 8
                ),
        ),
        "padding-bottom": (
                "prefix": "pb",
                "values": (
                        "0": 0,
                        "1": $base-padding,
                        "2": $base-padding * 2,
                        "3": $base-padding * 4,
                        "4": $base-padding * 6,
                        "5": $base-padding * 8
                ),
        ),
        "margin": (
                "prefix": "m",
                "values": (
                        "0": 0,
                        "1": $base-margin,
                        "2": $base-margin * 2,
                        "3": $base-margin * 4,
                        "4": $base-margin * 6,
                        "5": $base-margin * 8
                ),
        ),
        "margin-left": (
                "prefix": "ml",
                "values": (
                        "0": 0,
                        "1": $base-margin,
                        "2": $base-margin * 2,
                        "3": $base-margin * 4,
                        "4": $base-margin * 6,
                        "5": $base-margin * 8
                ),
        ),
        "margin-right": (
                "prefix": "mr",
                "values": (
                        "0": 0,
                        "1": $base-margin,
                        "2": $base-margin * 2,
                        "3": $base-margin * 4,
                        "4": $base-margin * 6,
                        "5": $base-margin * 8
                ),
        ),
        "margin-top": (
                "prefix": "mt",
                "values": (
                        "0": 0,
                        "1": $base-margin,
                        "2": $base-margin * 2,
                        "3": $base-margin * 4,
                        "4": $base-margin * 6,
                        "5": $base-margin * 8
                ),
        ),
        "margin-bottom": (
                "prefix": "mb",
                "values": (
                        "0": 0,
                        "1": $base-margin,
                        "2": $base-margin * 2,
                        "3": $base-margin * 4,
                        "4": $base-margin * 6,
                        "5": $base-margin * 8
                ),
        ),
        "opacity": (
                "prefix": "o",
                "values": (
                        "10": 0.1,
                        "20": 0.2,
                        "30": 0.3,
                        "40": 0.4,
                        "50": 0.5,
                        "60": 0.6,
                        "70": 0.7,
                        "80": 0.8,
                        "90": 0.9,
                        "100": 1,
                ),
        ),
        "border-radius": (
                "prefix": "br",
                "values": (
                        "default": $base-border-radius,
                        "none": 0,
                        "xs": math.div($base-border-radius, 4),
                        "sm": math.div($base-border-radius, 2),
                        "lg": $base-border-radius * 2,
                        "full": 50%,
                )
        ),
        "display": (
                "prefix": "display",
                "values": (
                        "n": none,
                        "b": block,
                        "f": flex,
                        "i": inline,
                        "i-b": inline-block
                )
        ),
        "font-size": (
                "prefix": "font",
                "values": (
                        "xs": $font-xs,
                        "sm": $font-sm,
                        "md": $font-md,
                        "lg": $font-lg
                )
        ),
        "font-weight": (
                "prefix": "font-w",
                "values": (
                        "b":bold,
                        "n":normal,
                        "l":ligther,
                        "2":200,
                        "4": 400,
                        "6": 600,
                        "7": 700,
                        "8": 800,
                        "9": 900
                )
        )
);

// generate utility classes
@each $property, $map in $utilities {
  $prefix: map-get($map, "prefix");
  $values: map-get($map, "values");

  @each $k, $v in $values {
    @if ($k == "default") {
      .#{$prefix} {
        #{$property}: $v;
      }
    } @else {
      .#{$prefix}-#{$k} {
        #{$property}: $v;
      }
    }
  }
}
