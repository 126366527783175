// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "~@angular/material" as mat;
// $ticketing-web-client-level: mat.define-typography-level(
//   $font-family: 'Averta',
//   $font-weight: 500,
//   $font-size: 14,
//   $line-height: 1,
//   $letter-spacing: normal,
// );

$ticketing-web-client-typography: mat.define-typography-config(
  $font-family: "Averta,Arial, Helvetica, sans-serif",
  $headline: mat.define-typography-level(52px, 1, 500),
  $button: mat.define-typography-level(14px, 1, 100),
);

@include mat.core($ticketing-web-client-typography);

$md-btxpalette: (
  50: #e1edfb,
  100: #b3d3f5,
  200: #81b6ef,
  300: #4e98e9,
  400: #2882e4,
  500: #026cdf,
  600: #0264db,
  700: #0159d7,
  800: #014fd2,
  900: #013dca,
  A100: #f2f5ff,
  A200: #bfceff,
  A400: #8ca7ff,
  A700: #7393ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ticketing-web-client-primary: mat.define-palette($md-btxpalette);
$ticketing-web-client-accent: mat.define-palette($md-btxpalette, A700, A100, A400);

// The warn palette is optional (defaults to red).
$ticketing-web-client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$ticketing-web-client-theme: mat.define-light-theme(
  (
    color: (
      primary: $ticketing-web-client-primary,
      accent: $ticketing-web-client-accent,
      warn: $ticketing-web-client-warn,
    ),
    typography: $ticketing-web-client-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ticketing-web-client-theme);

/* You can add global styles to this file, and also import other style files */

// utilities (margin, padding, opacity, etc)

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

/*
//You can add global styles to this file, and also import other style files
//
*/

// regular style toast
@import "~ngx-toastr/toastr";

// Swiper
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

//----------------------------------*\
// Google Fonts
//----------------------------------*/
//@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
//@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

//@import url("https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0/dist/fancybox.css");

//variables & functions
@import "core/variables";

//----------------------------------*\
// Fonts
//----------------------------------*/
@import "core/fonts";

// base & layout
@import "core/_base";
@import "core/breakpoints";
@import "core/grid";

// colors
@import "core/colors";

//@import "core/theming/theming";
@import "core/utilities";

// components (button, card, navbar etc)
@import "core/components/card";
@import "core/components/button";
@import "core/components/dialog";
@import "core/components/form";
@import "core/components/input";
@import "core/components/select";

body {
  color: #262626;
}

html,
body {
  height: 100%;
  margin: 0;
  //overflow-x: hidden !important;
  scroll-behavior: smooth;
}

//TODO:
//.mat-drawer-container{
//  display: unset !important;
//}

.sticky-wrapper {
  min-height: 100%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  // margin-bottom: -423px;
}

.footer,
.push {
  height: 423px;
}

.performance-page-container {
  .performance-order-summary {
    mat-expansion-panel-header {
      .mat-expansion-indicator {
        position: absolute;
        left: 20px;
        top: 5px;
      }
      span.mat-content {
        flex-direction: column !important;
      }
    }
  }
}

.sidenav-container {
  background: #fff;
}


// lang-iw class'ı altında yazılan CSS kodları tamamen ibraince dilindeki Right To Left akışın oluşturduğu bozuklukları düzeltmek içindir haricinde ekstra bir durum için CSS yazılmamıştır, yazılması tavsiye edilmez. style.scss'ye yazılmasının sebebi ise component'ın kendi scss dosyasından bazen değişikliği important! ile dahi kabul etmiyor olmasıdır.

.lang-iw{
  .cart-count {
    right: 80px !important;
    left: inherit !important;
  }
  .cart-dropdown{
    margin-left: 0px !important;
  }
  .account-menu{
    //margin-left: 20px !important;
  }
  .do-not-have-account{
    margin-left: 10px;
    margin-right: 0 !important;
  }
  .performance-listing{
    .divider{
        margin: 0px 0px 0px 15px !important;
    }
  }
  .event-description{
    .icon{
      margin-left: 0px !important;
      margin-right: 8px !important;
    }
  }
  .ticket-selection-content{
    .text{
      padding-left: 0 !important;
      padding-right: 10px !important;
    }
  }
  .mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content{
    flex-direction: row-reverse !important;
  }
  .category-menu .mat-list-base .mat-list-item a{
    text-align: right !important;
  }
  .category-menu .mat-list-base .mat-list-item .right-arrow {
    left: 10px !important;
    right: inherit !important;
  }
  .sign-up-checkbox-list{
    align-items: flex-start !important;
    text-align: right !important;
    .mat-checkbox-layout{
      text-align: right !important;
    }
     
      .mat-checkbox-inner-container {
        margin-right: 0px !important;
        margin-left: 8px !important;
      }
    
  }
  .mat-select-arrow {
    &::after{
        right: -25px  !important;
    }
  }
  .past-events-container .mat-badge{
    display: none !important;
  }
  .past-events-container .past-events-content-wrapper .account-month-section .my-events-card .event-detail-container .event-info{
    span {
      margin-right: 0 !important;
      margin-left: 20px !important;
    }
    mat-icon{
      margin: 0px 0px 0px 4px !important;
    }
  }
  .all-orders-container  .all-orders-content-wrapper .account-month-section .ticket-summary-wrapper .ticket-summary-top-info .small-product-text .small-product-text-detail{
    display: flex;
  }
  .all-orders-container  .event-description{
    .icon{
      margin-left: 4px !important;
      margin-right: 8px !important;
    }
  }
  .address-item .menu-container{
    left: 0 !important;
    right: inherit !important;
  }
  .address-list-container{
    flex-direction: row-reverse !important;
  }
  .countdown-wrapper{
    padding-right: 0 !important;
  }
  .cart-page-container .cart-layout .cart-content .cart-header .total-ticket .ticket-count{
    padding: 5px 10px 0px 0px !important;
  }
  .header-countdown-wrapper{
    // top: 20px;
    // left: 6px;
    // right: inherit !important;
  }
  footer .corporate-pages{
    float: right !important;
    li{
      float: right !important;
      border-right: none !important;
      border-left: 1px solid #fff;
      &:first-child{
        padding-right: 0px !important;
        padding-left: 15px !important;
      }
    }
  }
  .event-image .ticket-count {
    right: -10px !important;
    bottom: 20px !important;
    &:before{
      right: 12px !important;
    }
  }
  .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
    left: 10px !important;
  }
  .product-actions .product-actions-wrapper .product-ticket-quantity-text span:nth-child(1){
    margin-left: 8px !important;
    margin-right: 0 !important;
  }
  .ticket_numbers_panel .mat-expansion-indicator{
    padding: 0 5px !important;
  }
  .mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix mat-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow::after{
    right: -15px !important;
  }
}
@media only screen and (max-width: 1024px){
  .lang-iw{
    footer{
      .footer-column-holder h2 {
        background: url(../src/assets/images/icon/white-arrow.svg) left center no-repeat !important;
      }
      .corporate-pages-title {
        background: url(../src/assets/images/icon/white-arrow.svg) left center no-repeat !important;
      }
    }
  }
}
@media only screen and (max-width: 959px) {
 .lang-iw{
  .cart-count {
    right: 0px !important;
  }
  .bottom-band-inside-holder{
    // flex-direction: row-reverse !important;
  }
  .cart-dropdown{
    // margin-right: 40px !important;
  }

   .help-button-container {
     margin-right: 5px;
     padding-top: 3px;
   }

  .header-countdown-wrapper{
    left: 40px !important;
  }
  .account-menu{
    margin-left: 0px !important;
    margin-right: 10px !important;
  }
 }
}


mat-error {
  span {
    min-height: 15px;
    display: block;
  }
}

.custom-toast-iw{
    top: 12px;
    left: 12px;
    right: unset;
}
