/**
Averta Light
 */
@font-face {
  font-family: "Averta";
  src: local("Averta"), url(../../fonts/averta-light-webfont.eot);
  src: local("Averta"), url(../../fonts/averta-light-webfont.eot?#iefix) format("embedded-opentype"),
  url(../../fonts/averta-light-webfont.woff2) format('woff2'),
  url(../../fonts/averta-light-webfont.woff) format('woff'),
  url(../../fonts/averta-light-webfont.svg#Averta) format('svg');
  font-weight: 100;
  font-style: normal;
  //font-display: swap;
}


/**
Averta Regular
 */
@font-face {
  font-family: "Averta";
  src: local("Averta"), url(../../fonts/averta-regular-webfont.eot);
  src: local("Averta"), url(../../fonts/averta-regular-webfont.eot?#iefix) format("embedded-opentype"),
  url(../../fonts/averta-regular-webfont.woff2) format('woff2'),
  url(../../fonts/averta-regular-webfont.woff) format('woff'),
  url(../../fonts/averta-regular-webfont.svg#Averta) format('svg');
  font-weight: 500;
  font-style: normal;
  //font-display: swap;
}


/**
Averta Semibold
 */
@font-face {
  font-family: "Averta";
  src: local("Averta"), url(../../fonts/averta-semibold-webfont.eot);
  src: local("Averta"), url(../../fonts/averta-semibold-webfont.eot?#iefix) format("embedded-opentype"),
  url(../../fonts/averta-semibold-webfont.woff2) format('woff2'),
  url(../../fonts/averta-semibold-webfont.woff) format('woff'),
  url(../../fonts/averta-semibold-webfont.svg#Averta) format('svg');
  font-weight: 700;
  font-style: normal;
  //font-display: swap;
}


/**
Averta Bold
 */
@font-face {
  font-family: "Averta";
  src: local("Averta"), url(../../fonts/averta-bold-webfont.eot);
  src: local("Averta"), url(../../fonts/averta-bold-webfont.eot?#iefix) format("embedded-opentype"),
  url(../../fonts/averta-bold-webfont.woff2) format('woff2'),
  url(../../fonts/averta-bold-webfont.woff) format('woff'),
  url(../../fonts/averta-bold-webfont.svg#Averta) format('svg');
  font-weight: 900;
  font-style: normal;
  //font-display: swap;
}


//body {
//font-family: $primary-font;
//font-size: $primary-fs;
//font-weight: $regular;
//}
