@mixin btn($bg-color: #e2e2e2) {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: $base-padding $base-padding * 2;
  border-radius: $base-border-radius;
  background-color: $bg-color;
}

.btn {
  @include btn;
}

@each $key, $val in $colors {
  .btn-#{$key} {
    @include btn($val);
    &:hover {
      background-color: lighten($val, 5);
    }
  }
  .btn-outlined-#{$key} {
    @include btn(#fff);
    border: $base-border-thickness solid $val;
    &:hover {
      background-color: $val;
    }
  }
  .btn-complement-#{$key} {
    @include btn($val);
    color: light-comp($val);
    &:hover {
      color: $val;
      background-color: light-comp($val);
    }
  }
}

.mat-button-base {
  //background: red;
  font-size: 14px;
  font-weight: 600;
  border-radius: $base-border-radius / 2;
}

/**
TODO: Bu şekilde button lar ezilmemelidir.
core/components/button.css te buttonlar customize edilmelidir.
 */

$defaultButtonFontSize: 16px;
$defaultButtonFontWeight: 600;
$defaultButtonFontAlign: center;

$defaultButtonLineHeight: 16px;
$defaultButtonPadding: 14px;

$defaultButtonBorder: 1px solid #bfdaf6;
$defaultButtonBorderRadius: 10px;
$defaultButtonBoxShadow: none;

$flatButtonFontSize: 14px;

$buttonFontColorWhite: #fff;
$buttonFontColorBlue: #026cdf;

$buttonBackgroundWhite: #fff;
$buttonBackgroundBlue: #026cdf;
$buttonBackgroundTransparent: transparent;

@mixin ButtonDefaultAtt {
  font-size: $defaultButtonFontSize;
  font-weight: $defaultButtonFontWeight;
  text-align: $defaultButtonFontAlign;

  line-height: $defaultButtonLineHeight;
  padding: $defaultButtonPadding;

  border: $defaultButtonBorder;
  border-radius: $defaultButtonBorderRadius;
  box-shadow: $defaultButtonBoxShadow;
}

@mixin ButtonTypes($background, $color) {
  background: $background;
  color: $color;
}

@mixin ButtonBasicAtt {
  font-size: $defaultButtonFontSize;

  font-weight: $defaultButtonFontWeight;
  text-align: $defaultButtonFontAlign;
  line-height: $defaultButtonLineHeight;
  padding: 10px 0px;

  box-shadow: none;
}

@mixin ButtonFlatAtt {
  font-size: $flatButtonFontSize;

  font-weight: $defaultButtonFontWeight;
  text-align: $defaultButtonFontAlign;
  line-height: $defaultButtonLineHeight;
  padding: 0px 0px;

  box-shadow: none;
  .mat-button-ripple {
    display: none !important;
  }
}

.mat-button-base {
  &.small-button {
    padding: 10px 14px !important;
    font-size: 14px !important;
    border-radius: 5px !important;
  }
  &.xsmall-button {
    padding: 5px 14px !important;
    font-size: 14px !important;
    border-radius: 5px !important;
  }
  .mat-button-wrapper{
    >*{
      vertical-align: initial !important;
      font-size:14px;
    }
  }
  mat-icon {
    margin: 0 5px 0 0;
    padding: 0px;
    height: 10px;
    margin-top: -20px;
    position: relative;
    font-size: 22px !important;
    top:7px !important;
  }
}
.mat-button {
  @include ButtonBasicAtt;
  @include ButtonTypes($buttonBackgroundTransparent, $buttonFontColorBlue);
  &.mat-button-focus-overlay {
    opacity: 0;
  }
}
.mat-button-base.mat-raised-button {
  @include ButtonDefaultAtt;
  @include ButtonTypes($buttonBackgroundBlue, $buttonFontColorWhite);
}

.mat-button-base.mat-stroked-button {
  @include ButtonDefaultAtt;
  @include ButtonTypes($buttonBackgroundWhite, $buttonFontColorBlue);
}

.mat-button-base.mat-flat-button {
  @include ButtonFlatAtt;
  @include ButtonTypes($buttonBackgroundTransparent, $buttonFontColorBlue);
}
