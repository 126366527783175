@import "src/assets/css/core/_variables";

.mat-form-field.mat-form-field-appearance-fill {
    .mat-form-field-wrapper {
        /*padding-top: 15px;*/
        .mat-form-field-subscript-wrapper {
            position: relative !important;
        }
        .mat-form-field-flex {
            background: $WhiteColor;
            border: solid 1px $DiatomiteColor;
            border-radius: 10px;
            .mat-form-field-infix {
                padding: 4px 0px 8px 0px;
                .mat-input-element {
                    margin: 0px;
                }
                .mat-form-field-label-wrapper {
                    
                    .mat-form-field-label {
                        /*top:14px;*/
                        mat-label {
                            color: $StateColor;
                            font-weight: 400;
                        }
                    }
                }
            }
            .mat-form-field-suffix {
                .mat-icon-button {
                    color: $primary;
                }
            }
        }
        .mat-form-field-underline {
            display: none;
        }
    }
    
    &.mat-form-field-invalid {
        .mat-form-field-flex {
            border: solid 1px $error;
        }
    }
    &.mat-form-field-has-label.ng-valid {
        .mat-form-field-flex {
            .mat-form-field-label-wrapper {
                .mat-form-field-label {
                    mat-label {
                        color: $primary;
                        font-size: 15px;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
